import React, { useState, useEffect } from "react"
import {
  CaseStudyTileContainer,
  CoverTextContainer,
  Title,
  Subtitle,
} from "./style"
import Img from "gatsby-image"
import TilePopUp from "./TilePopUp"

const CaseStudyTile = ({
  img,
  caseImg,
  title,
  subtitle,
  selectedTile,
  setSelectedTile,
  body,
  video,
}) => {
  const [showPopUp, setShowPopUp] = useState(false)
  const [videoClicked, setVideoClicked] = useState(false)

  img.node.childImageSharp.fluid.sizes = "(min-width: 768px) 534px, 100vw"

  const closeHandler = () => {
    setShowPopUp(false)
    setSelectedTile(null)
    setVideoClicked(false)
  }

  const escHandler = event => {
    if (event.keyCode === 27 && showPopUp) {
      closeHandler()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escHandler)

    return function cleanup() {
      window.removeEventListener("keydown", escHandler)
    }
  })

  //closes other open tiles on selection of a tile
  useEffect(() => {
    if (selectedTile !== img && showPopUp) {
      setShowPopUp(false)
    }
  }, [selectedTile])

  return (
    <CaseStudyTileContainer
      onClick={() => {
        setShowPopUp(true)
        setSelectedTile(img)
      }}
    >
      <Img fluid={img && img.node.childImageSharp.fluid} />
      <CoverTextContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </CoverTextContainer>
      <TilePopUp
        showPopUp={showPopUp}
        caseImg={caseImg}
        title={title}
        subtitle={subtitle}
        body={body}
        video={video}
        setShowPopUp={setShowPopUp}
        videoClicked={videoClicked}
        closeHandler={closeHandler}
        setSelectedTile={setSelectedTile}
        setVideoClicked={setVideoClicked}
      />
    </CaseStudyTileContainer>
  )
}

export default CaseStudyTile
