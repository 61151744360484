import React from "react"
import { LogoImage, LogoCol, LogoWallWrapper } from "./style"
import { FlexGrid, FlexRow } from "components/FlexGrid"
import { GetClientLogoImages } from "queries/GetClientLogoImages"
import { MainColumn } from "../MainColumn"

const LogoWall = () => {
  const logoImages = GetClientLogoImages()

  const logosData = [
    { client: "aeroplane", website: "https://www.aeroplanejelly.com.au/" },
    { client: "arcare", website: "https://arcare.com.au/" },
    { client: "asahi", website: "https://www.asahipremiumbeverages.com/" },
    { client: "bapcor", website: "https://www.bapcor.com.au/" },
    { client: "brown-brothers", website: "https://www.brownbrothers.com.au/" },
    { client: "carpet-court", website: "https://www.carpetcourt.com.au/" },
    { client: "conga-foods", website: "https://www.congafoods.com.au/" },
    { client: "dulux", website: "https://www.dulux.com.au/" },
    {
      client: "earth-choice",
      website: "https://naturesorganics.com.au/brand/earth-choice/",
    },
    { client: "evo-health", website: "https://www.evolutionhealth.com.au/" },
    { client: "keens", website: "https://www.mccormick.com.au/keens" },
    { client: "life-space", website: "https://www.lifespaceprobiotics.com/" },
    { client: "mattel", website: "https://www.mattel.com/en-us" },
    { client: "mayvers", website: "https://mayvers.com.au/" },
    { client: "mccormick", website: "https://www.mccormick.com.au/" },
    {
      client: "organic-care",
      website: "https://naturesorganics.com.au/brand/organic-care/",
    },
    { client: "petstock", website: "https://www.petstock.com.au/" },
    { client: "porters-paints", website: "https://www.porterspaints.com/" },
  ]

  let clients = logoImages.map(img => {
    return logosData.map(logo => {
      if (img.node.name.includes(logo.client)) {
        return Object.assign({ ...logo, img: img })
      }
    })
  })

  clients = [].concat.apply([], clients).filter(Boolean)

  // console.log(clients)

  return (
    <LogoWallWrapper>
      <MainColumn>
        <h2>You’re in good company.</h2>
      </MainColumn>

      <FlexGrid gap={[5, 20, 40]} rowGap={[20, 25, 50]}>
        <FlexRow>
          {clients.map((client, index) => {
            return (
              <LogoCol key={index} xs={4} sm={3} md={2}>
                <a
                  href={client.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LogoImage fluid={client.img.node.childImageSharp.fluid} />
                </a>
              </LogoCol>
            )
          })}
        </FlexRow>
      </FlexGrid>
    </LogoWallWrapper>
  )
}

export default LogoWall
