import styled from "styled-components"
import { device } from "breakpoints"
import Img from "gatsby-image"
import { FlexCol } from "components/FlexGrid"

export const LogoWallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0;
  @media ${device.tablet} {
    padding: 80px 0;
  }

  @media ${device.desktop} {
    padding: 100px 0;
  }

  @media ${device.large} {
    padding: 140px 0;
  }

  h2 {
    color: #ff7f41;
    font-size: 36px;
    max-width: 70%;
    margin-bottom: 24px;
    @media ${device.tablet} {
      font-size: 56px;
    }

    @media ${device.desktop} {
      margin-bottom: 40px;
      font-size: 64px;
      max-width: 60%;

    }
    @media ${device.large} {
      margin-bottom: 70px;
      font-size: 72px;
    }
  }
`

export const LogoImage = styled(Img)`
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: 130ms;


  @supports (filter: grayscale(100%)) {
    opacity: 0.4;
  }
`

export const LogoCol = styled(FlexCol)`
  position: relative;
  :hover {
    ${LogoImage} {
      filter: none;
      opacity: 1;
    }
  }

  a {
    :before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
