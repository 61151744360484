import styled from "styled-components"
import { device } from "breakpoints"
import { FlexGrid, FlexCol } from "components/FlexGrid"
import ReactPlayer from "react-player"
import Img from "gatsby-image"
export const PlayButton = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 160ms;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0);
  opacity: ${(props) => (props.videoClicked ? 0 : 1)};
  pointer-events: none;
  img {
    height: 64px;
    width: 64px;
  }

  h5 {
    font-size: 12px;
    color: white;
    letter-spacing: 1.33px;
    text-align: center;
    font-weight: 500;
    line-height: 36px;
  }
`

export const CaseImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
export const CaseStudyFlexGrid = styled(FlexGrid)`
  max-width: 1600px;

  ::after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background: black;
    top: 0;
    transition: 160ms;
    opacity: ${(props) => (props.selectedTile ? 0.5 : 0)};
    pointer-events: ${(props) => (props.selectedTile ? "all" : "none")};
    left: 0;
    z-index: 2;
  }
`
export const CaseStudyTileContainer = styled.div`
  position: relative;
  height: 100%;
  padding-top: 75%;

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-image: linear-gradient(
      188deg,
      rgba(0, 0, 0, 0) 58%,
      rgba(0, 0, 0, 0.75) 100%
    );
  }
`
export const CoverTextContainer = styled.div`
  position: absolute;
  width: 100%;
  padding: 0 40px;
  bottom: 28px;
  z-index: 1;
  cursor: pointer;
  transition: opacity 330ms;
  opacity: 0;

  ${CaseStudyTileContainer}:hover & {
    opacity: 1;
  }
`

export const Title = styled.h3`
  color: white;
  margin-bottom: 8px;
  font-weight: 400;
  letter-spacing: 1.56px;
  line-height: 116%;

  font-size: 14px;

  @media ${device.desktop} {
    font-size: 18px;
  }
`
export const Subtitle = styled.h4`
  margin-bottom: 0;
  color: white;
  line-height: 116%;
  font-size: 28px;

  @media ${device.desktop} {
    font-size: 36px;
  }
`
export const TilePopUpContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #f8f7f5;
  z-index: 3;
  transition: 160ms;
  display: flex;
  opacity: ${(props) => (props.showPopUp ? 1 : 0)};
  pointer-events: ${(props) => (props.showPopUp ? "all" : "none")};
  flex-direction: column;
  box-shadow: 0 10px 150px 0 rgba(0, 0, 0, 0.75);

  @media ${device.tablet} {
    flex-direction: row;
    width: 200%;
    align-items: flex-start;
  }
`

export const VideoThumbnail = styled.div`
  padding-top: 75%;
  flex-shrink: 0;
  position: relative;
  background: teal;

  padding-top: ${(props) => props.woodstock && "150%"} !important;
  padding-top: ${(props) => props.somersby && "114%"} !important;
  padding-top: ${(props) => props.vcs && "131%"} !important;

  @media ${device.tablet} {
    flex-basis: 50%;
    padding-top: 37.5%;

    padding-top: ${(props) => props.woodstock && "75%"} !important;
    padding-top: ${(props) => props.somersby && "57%"} !important;
    padding-top: ${(props) => props.vcs && "66.5%"} !important;
  }

  :hover {
    ${PlayButton} {
      opacity: ${(props) => (props.videoClicked ? 0 : 0.7)};
    }
  }
`

export const InfoWrapper = styled.div`
  position: relative;
  padding: 40px 18px 20px 18px;
  @media ${device.tablet} {
    padding: 25px;
    padding-bottom: 10px;
  }
  @media ${device.desktop} {
    padding: 40px;
    padding-bottom: 20px;
  }

  @media ${device.large} {
    padding: 60px;
    padding-bottom: 40px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
`

export const PopUpTitle = styled(Title)`
  color: #ff7f41;
`

export const PopUpSubtitle = styled(Subtitle)`
  color: ${(props) => props.theme.primaryColor};
  margin-bottom: 30px;
`
export const SmallTitle = styled.h5`
  font-size: 20px;
  color: #3f3f3f;
  margin-bottom: 14px;

  @media ${device.large} {
    font-size: 24px;
    margin-bottom: 11px;
  }
`
export const TileCol = styled(FlexCol)`
  opacity: 0;
  @media ${device.tablet} {
    :nth-of-type(2n) {
      ${TilePopUpContainer} {
        right: 0;
        left: auto;
        flex-direction: row-reverse;
      }
    }
  }

  @media ${device.desktop} {
    :nth-of-type(2n) {
      ${TilePopUpContainer} {
        right: auto;
        left: 0;
        flex-direction: row;
      }
    }
    :nth-of-type(3n) {
      ${TilePopUpContainer} {
        right: 0;
        left: auto;
        flex-direction: row-reverse;
      }
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 17px;
  right: 17px;
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  padding: 5px;
  svg {
    width: 17px;
    height: 17px;
    path {
      transition: 120ms;
      fill: #9f9c98;
    }
  }

  :hover {
    svg {
      path {
        fill: #6f6d68;
      }
    }
  }
`

export const ReactVideo = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  width: 100% !important;
  height: 100% !important;
  outline: none;
  cursor: pointer;

  video {
    outline: none;
  }
`
