import React, { useState, useEffect } from "react"
import anime from "animejs/lib/anime.es.js"
import { FlexRow } from "components/FlexGrid"
import CaseStudyTile from "./CaseStudyTile"
import { GetCaseStudyTileImages } from "queries/GetCaseStudyTileImages"
import { caseStudiesData } from "./caseStudiesData"
import { GetCaseStudyCaseImages } from "queries/GetCaseStudyCaseImages"
import { CaseStudyFlexGrid, TileCol } from "./style"

const CaseStudyGrid = () => {
  const tileImages = GetCaseStudyTileImages()
  const caseImages = GetCaseStudyCaseImages()
  const [selectedTile, setSelectedTile] = useState(null)

  const animationTimeline = () => {
    const timeline = anime.timeline()
    timeline.add(
      {
        targets: ".tileCol",
        opacity: [0, 1],
        easing: "easeOutSine",
        duration: 1000,
        delay: (el, i) => 0 + 100 * i,
      },
      "+=1000"
    )
  }
  useEffect(() => {
    animationTimeline()
  }, [])

  let tiles = caseStudiesData.map((tile) => {
    return Object.assign({
      ...tile,
      tileImg: tileImages.find((img) => img.node.name.includes(tile.imgFile)),
      caseImg: caseImages.find((img) => img.node.name.includes(tile.imgFile)),
    })
  })

  // console.log(tiles)
  return (
    <CaseStudyFlexGrid fullWidth gap={[0]} selectedTile={selectedTile}>
      <FlexRow>
        {tiles.map((tile, index) => {
          return (
            <TileCol key={index} xs={12} sm={6} md={4} className="tileCol">
              <CaseStudyTile
                img={tile.tileImg}
                title={tile.title}
                subtitle={tile.subtitle}
                body={tile.body}
                video={tile.video}
                caseImg={tile.caseImg}
                selectedTile={selectedTile}
                setSelectedTile={setSelectedTile}
              />
            </TileCol>
          )
        })}
      </FlexRow>
    </CaseStudyFlexGrid>
  )
}

export default CaseStudyGrid
