export const caseStudiesData = [
  {
    imgFile: "aeroplane-jelly",
    video:
      "https://res.cloudinary.com/avenue/video/upload/v1594345171/AeroplaneJelly_wtxllp.mp4",
    title: "Aeroplane Jelly",
    subtitle: "Swinging Celebration",
    body: {
      mapping: `    Aeroplane Jelly had lost some of its relevance over the years. The much loved brand was approaching its 90th Birthday so we had the opportunity to bring it back on the radar and appeal to everyone’s inner child!
      `,
      starting: `With such strong brand equity 90 years later, the iconography of a girl swinging and singing was still relevant! We reinvigorated the usage of the classic and iconic brand assets featuring the swinging girl and infamous “I like Aeroplane Jelly” jingle. The campaign achieved a sales increase and moved the Aeroplane Jelly brand out of decline!
      `,
      going: `Despite being bucked off a horse, knocked out cold and taken to hospital the day prior, our CMC MD didn’t want to let her near death experience get in the way of this iconic shoot. She had a doctor’s certificate to allow her on the plane, and was there with bells on… plus a black eye.`,
      transportation: `The brand launched with a birthday party event to celebrate the 90th year. This included Sunrise live weather crosses, an extensive 30s and 15s TVC campaign with the Seven Network supported by integrated social media, PR, Activation, Digital and in store campaigns, as well as extensive Nickelodeon and Nick Jr sponsorship. 
      `,
    },
  },

  {
    imgFile: "mythbusters",
    video:
      "https://res.cloudinary.com/avenue/video/upload/v1594345109/MythBusters_xu0tyn.mp4",
    title: "Dulux",
    subtitle: "Putting Wash&Wear to the test",
    body: {
      mapping: `Dulux was undertaking the biggest product launch in Dulux history so we saw the opportunity to come up with an idea to match in magnitude and universal appeal! Our key challenge was to convince our audience that the new Dulux Wash&Wear paint is different. 
`,
      starting: `The strategy was to bring to life the Dulux claims in a way that was  not only relevant but also connects to our audience while driving home the innovation to stay ahead of the competition. Most importantly, we needed a big idea to cut-through, shock and engage with something that has never been seen before so we asked the MythBusters to put the all new Dulux Wash&Wear to the test. The campaign resulted in the highest ever brand effectiveness scores ever as measured by Millward Brown! 
`,
      going: `Originally a PR brief, we pushed past our limitations to create a meaningful campaign demonstrating Dulux in a stand out way. Due to the San Francisco time difference, we worked day and night, then flew to San Francisco to get the MythBusters on board to literally throw everything at the Dulux paint range. To add a bit of theatre, the CMC team also dressed up as the MythBusters during the pitch process.
`,
      transportation: `Shot over two days, the campaign was brought to life with four 90s, 30s and 15s TVCs testing each Dulux Wash&Wear paint product using inventions created by the MythBusters themselves. The campaign was amplified via a FTA TV media strategy and supported by social media campaign content.
      Photographer: Lisa Cohen
`,
    },
  },
  {
    imgFile: "squeaky-gate",
    video:
      "https://res.cloudinary.com/avenue/video/upload/v1594345069/Squeaky_Gate_izivls.mp4",
    title: "Squeaky Gate",
    subtitle: "The Way It Should Be",
    body: {
      mapping: `The CMC team was tasked with the challenge to disrupt the cluttered olive oil category with a campaign that was memorable for Squeaky Gate Extra Virgin Olive Oil. With its quirky name, injecting humour into the campaign made perfect sense.
`,
      starting: `We developed ‘The way it should be’ creative platform which was  inspired by the brand’s true-blue, farmer to consumer, production process. The campaign content has a playful dig at the theatre behind branded content and also features famous Australian comedian, Jean Kittson, to bring to life a character that would resonate with the target audience. The TVCs underwent rigorous testing which reaffirmed that our target audience connected with the content and the sales results confirmed this as well with the brand experiencing an uplift during the campaign period. 
`,
      going: `It’s imperative that good comedy has great talent and scripting to bring it to life! The CMC team went beyond with months of research and phone calls to find the best possible talent to play the role of Squeaky Gate’s Kerryn Fogarty character. 
`,
      transportation: `The Squeaky Gate ‘The way it should be’ campaign included a 30s TVC as well as supporting 15s and 6s content pieces which were amplified via a FTA TV strategy as well as digital channels. 
`,
    },
  },
  {
    imgFile: "arcare",
    title: "Arcare aged care",
    subtitle: "5 star care",
    body: {
      mapping: `The CMC team was appointed to manage a PR retainer for Arcare aged care in 2018. With a brand essence centred around community, we saw an ongoing opportunity to cut-through with emotive storytelling.
`,
      starting: `The CMC team works closely with Arcare’s Lifestyle Coordinators at each residence to uncover positive stories and initiatives. Our ongoing strategy is to share the feel good, community spirit of Arcare residences to break down stereotypes of aged care and foster connection with the wider community. The Arcare PR team consistently achieves stories in major metropolitan and local newspapers including the Herald Sun and television stations including Ten News First, delivering an audience reach of 137 million over a 12-month period (2018-2019), 
`,
      going: `The CMC PR team goes beyond PR coverage for Arcare aged care to facilitate activities that deliver value for the senior residents. This included engaging Marina Prior and Rhonda Burchmore on behalf of Arcare to host a one-hour Mother’s Day virtual performance to surprise all the mothers and grandmothers in the Arcare community with a special treat during the COVID-19 lockdowns. 
`,
      transportation: `The ongoing PR retainer for Arcare aged care includes multiple PR tactics such as events, media interviews and photo opportunities.
`,
    },
  },
  {
    imgFile: "bostik",
    video:
      "https://res.cloudinary.com/avenue/video/upload/v1594345157/Blu_Tack_zjri9a.mp4",
    title: "Bostik",
    subtitle: "For all Blu-Tackers",
    body: {
      mapping: `Everyone’s familiar with Blu-Tack but it’s not always top of mind so our challenge was to celebrate the brand and make it relevant again. What better way to bring it to life than to showcase its versatility and usage occasions?
`,
      starting: `Blu-Tack is loved by all, young and old! Our strategy was to develop the ‘For all Blu-Tackers’ creative direction to establish a campaignable platform that can extend across multiple age brackets and extensions. We brought to life a Blu Tack brand character to resonate with all aged groups via video content which delivered more than three million impressions via digital channels as well as a total OOH reach of more than 23 million. 
`,
      going: `In addition to the best possible outcomes, at CMC we also strive for efficiencies. We’ll even go beyond to source talent internally to include kids of CMC team members or our production partners as well as friends of friends who are keen to feature in some of our creative to assist clients with keeping costs down!
`,
      transportation: `There was a combination of 6s and 15s video assets created for the campaign which were amplified via YouTube and Catch Up TV as well as DOOH media to build top of mind awareness on the path to purchase. 
`,
    },
  },
  {
    imgFile: "vcs",
    title: "VCS Foundation",
    subtitle: "World first trial",
    body: {
      mapping: `The VCS Foundation engaged CMC to drive recruitment in the Compass clinical trial for cervical cancer.  We saw an opportunity to target  women where they were most likely to take further action, by highlighting their nearest participating clinic in their local media.  
`,
      starting: `The CMC team executed a highly localised campaign to profile the GPs and clinics taking part in their local media. We matched close to 100 clinics with their media outlets and identified the spokespeople available to build the local relevance.  The initiative resulted in 169 media clips and on completion of the campaign, 1,000 additional women had been recruited with a steady flow of 60 samples coming through per week!
`,
      going: `To gain a deeper understanding of the process as well as secure a TV opportunity, one of our very own CMC team members went on the frontline to undertake the clinical trial herself!
`,
      transportation: `The VCS Foundation Compass Trial PR campaign included photo and interview opportunities across TV, print and radio to secure coverage. 
`,
    },
  },
  {
    imgFile: "somersby",
    title: "Somersby",
    subtitle: "PERSUADING MILLENNIALS IN STORE",
    body: {
      mapping: `We were tasked persuading millennials to consider Somersby Cider at shelf. With a decline in alcohol consumption within this age group, we needed to establish an engaging way to capture their attention.
      `,
      starting: `The strategy was to activate the campaign in an environment native to our audience and drive sales by creating an interactive brand experience, and more importantly, establish prize options that tap into what appeals to the millennial lifestyle. 
      `,
      going: `Engaging with millennials meant that we needed our Chatbot to communicate in the right language. The CMC team interviewed the actual millennials in the office and beyond, to ensure our use of emojis and acronyms were on point. The Chatbot was also extensively tested by all members of the CMC team to ensure any last-minute glitches were ironed out before we were live.
      `,
      transportation: `We created a Somersby Cider consumer promotion using a Messenger Chatbot mechanic. The campaign was  amplified via a POS suite, and shoppers were rewarded with instant prizes in exchange for unique codes found on pack. 
      `,
    },
  },
  {
    imgFile: "dulux",
    title: "Dulux",
    subtitle: "LIVING IN FULL COLOUR",
    body: {
      mapping: `Dulux want to encourage Australians to use colour in their homes; enter the annual Dulux Colour Forecast. Combining international travel, research, expertise, trends and editorial imagery, this year-long PR program simplifies the colour selection process, inspiring consumers to renovate with Dulux paint.
`,
      starting: `The ongoing program continues to strengthen the brand’s position as category leader year-on-year and share of voice; resulting in close to 1,000 clippings with an audience reach over 150 million for 2019.
`,
      going: `At CMC, we have a vested interest in design – be it for a shoot or event – and will do what is necessary to achieve high-quality output. This includes being on set assisting the stylist and photographer, packing media gifts, as well as straddling ladders to hang artwork for events with a limited bump in time.
`,
      transportation: `Launching the program to market involved engaging stylists to bring the campaign to life in visuals; coordinating photo and video shoots for PR, social and website use; generating media materials, customised for consumer and specifier media; media house visitation with journalists; two media launch events in Melbourne and Sydney; and media relations where Dulux colour experts were offered for interview.
`,
      end: `Photographer: Lisa Cohen`,
    },
  },
  {
    imgFile: "mattel",
    title: "Mattel",
    subtitle: "GAME CHANGER FOR MATTEL",
    body: {
      mapping: `To make a family favourite relevant again, Mattel engaged CMC to launch Aussie Scrabble. The initial idea evolved from an Irishman working at Mattel Australia who had compiled a list of Aussie lingo to assimilate with his colleagues. This was the catalyst for what was to become the biggest board game launch in the company’s local history and a world-first concept that to date remains the most innovative campaign launch in the company globally. We saw an opportunity to leverage iconic Australians as well as engage the Australian community to make this campaign larger than life. 
`,
      starting: `A key consumer insight – that families play more board games on holiday – led Mattel to develop a strategic partnership with Jetstar, to generate hype for Aussie Scrabble’s launch by crowdsourcing words for inclusion on Jetstar’s social channels which was promoted by CMC in a pic opp in the Herald Sun. The highlight was our media event to officially launch  the world-first at Melbourne Airport – complete with a custom Aussie Scrabble wrapped Boeing A320 and iconic celebrity ambassador Stephen Curry. The initiative helped to build relevance for the iconic Scrabble brand and achieved TV, radio, print and online coverage with a reach in the hundreds of millions. 
`,
      going: `At CMC, we are dedicated to understanding the impact of our campaigns and ensure they achieve the objectives. We went beyond to test the effectiveness of the Aussie Scrabble Launch with independent consumer research undertaken by Omnipoll revealing that 1 in 4 Australians heard about Aussie scrabble as a result! And as a result CMC’s campaign was Highly Commended at the 2018 Mumbrella CommsCon Awards.  The Aussie Scrabble launch also demonstrates how we’re able to work with clients and their partners from the inception of an idea, through to the execution and delivery, even if it means wrapping a commercial plane in Aussie lingo.
`,
      transportation: `CMC orchestrated a PR event at Melbourne Airport which led to extensive photo and filming opportunities being secured in national media titles across Australia.
`,
    },
  },
  {
    imgFile: "mayvers",
    title: "Mayvers",
    subtitle: "CRACKING A SATURATED CATEGORY",
    video: `https://res.cloudinary.com/communicado/video/upload/v1579564312/Website/CASE_STUDY_4_Mayvers_TVC.mp4`,
    body: {
      mapping: `The Mayver’s Peanut Butter brand expanded from health stores and independent supermarkets to national supermarket chains so our task was to help build awareness. We saw an opportunity to bring Mayver’s point of difference to life and offer consumers something that’s nothing but goodness across multiple touchpoints. 
`,
      starting: `We developed a brand platform with substance for Mayver’s Peanut Butter. To highlight the brand’s integrity and that the spread is made with pure ingredients and no added anythings, we established the ‘Nothing but goodness’ creative platform to celebrate the values of the Mayver’s brand through authentic storytelling. Despite a crowded market, we moved the brand from a healthy ingredient to a table staple. The result? Double digital sales increase!
`,
      going: `With the peanut harvester rolling in to harvest the crop, the pressure was on for the first Mayver’s Peanut Butter shoot but the CMC team managed to get it approved and shot within two weeks! 
`,
      transportation: `The campaign included a TVC media strategy to shift Mayver’s Peanut Butter into mass awareness. 
`,
    },
  },
  {
    imgFile: "woodstock",
    title: "Woodstock",
    subtitle: "A WINNER FOR WOODSTOCK",
    body: {
      mapping: `We were tasked with the challenge to not only build awareness of classic Bourbon & Cola brand, Woodstock, but also to drive sales. We needed to uncover compelling reasons to buy in order to motivate our target shopper to take notice of the brand at shelf.
`,
      starting: `Leveraging shopper insights, we developed “must have” prize offerings in conjunction with easy competition mechanics to help drive trial in a series of consumer promotions for the brand. This included a Woodstock Tyre Cooler which was custom created,  as well as a Total Tools voucher promotion which achieved a 50% uplift in total entries making it one of the most successful promotions for the Woodstock brand to date. 
`,
      going: `In their down time, the shopper marketing team at CMC go beyond each brief to research and build relationships with a multitude of prize partners so they’re ready and armed with a library of the most compelling options which can be activated quickly. To deliver the bespoke Tyre Cooler, the CMC team nurtured a relationship with manufacturers in China which not only guaranteed fast turnaround, but also achieved the most cost efficient approach possible for the client. 
`,
      transportation: `In partnership with Total Tools, we created a Woodstock Bourbon consumer promotion offering the chance to win Total Tools vouchers and we also activated a Woodstock Tyre Cooler promotion giving shoppers the chance to win one in store with the purchase of any Woodstock product. Both promotions were amplified with a disruptive in-store POS suite, as well as a competition mechanic with minimal barriers to entry.
`,
    },
  },
  {
    imgFile: "pet-stock",
    title: "Pet Stock",
    subtitle: "LOVE KNOWS NO BREED",
    body: {
      mapping: `Each year, CMC is challenged to elevate the awareness of PETstock Assist’s National Pet Adoption Day. It’s imperative that the initiative is approached with a fresh lense each year to provide journalists with new news and engaging angles.
`,
      starting: `Every year, our creative campaign platforms not only drive cut through, exceptional media coverage and event attendance, they also make a real difference to rescue pets in Australia. Our strategic approach has elevated National Pet Adoption Day beyond an event into a powerful education campaign to breakdown the stereotypes and barriers to adoption many pets face, all while highlighting PETstock Assist as a leader in animal welfare. To challenge misconceptions on ‘aggressive’ breeds and bust myths on rescue pets’ backgrounds and behaviours, we’ve removed breed labels on event partner PetRescue’s website, engaged animal behaviourists and encouraged Aussies to ‘Adopt Different’ - to keep an open mind and consider a species, breed or age they haven’t before. The campaigns consistently achieve several hundred to a thousand pieces of coverage with an audience reach in the millions, and most importantly, help find homes for 500-1,000 rescue pets each year.
`,
      going: `Our hands on approach to photoshoots, video shoots and media opps has seen as perfect the art of managing animal talent - no mean feat! We’ve coordinated shoots with horses, rabbits, horses and rabbits,  20+ cats in one room and even a doggy tea party! As an office of animal lovers, our team (including those not on the account) attend as many Adoption Days at PETstock stores as possible, volunteer and support participating rescue groups and after attending the 2020 event, our Account Director even brought home one of the dogs who didn’t find a home, lovingly fostering him for four weeks until he found his forever home. 
`,
      transportation: `The PETstock Assist National Pet Adoption Day is brought to life through PR tactics including robust pitching, media interviews and photo opportunities
`,
    },
  },
  {
    imgFile: "earth-choice",
    title: "Earth Choice",
    subtitle: "CAMPAIGN WITH A CAUSE",
    video: `https://res.cloudinary.com/communicado/video/upload/v1579577802/Website/CASE_STUDY_7_Earth_Choice_TVC.mp4`,
    body: {
      mapping: `Eco-friendly cleaning brand, Earth Choice, offers an affordable and genuine alternative for consumers looking for a better outcome for their homes and environment. However, many perceive that ‘natural’ products are ineffective and they are overlooked as a result. To build awareness of the brand and its eco-friendly proof points, we needed to delve into consumer sentiments to discover the barriers and opportunities that would convince our audience to switch.
`,
      starting: `We facilitated consumer research which underpinned a powerful brand strategy, new positioning and uncovered opportunities for the Earth Choice brand within the category. These insights underpinned the ‘Behind the Curtain’ campaign which explores the idea that it can be so easy to make more ethical choices and inspires our audience to consider their everyday impact on the environment. With a robust strategy and big idea beyond category norms, the brand achieved a considerable sales uplift.
`,
      going: `At CMC, we’re huge advocates for environmentally friendly practices so we were especially inspired by what the Earth Choice brand stands for. We were keen to get hands on so we assisted in the facilitation of consumer research and went beyond the brief to present our adcepts to get first-hand insight into our audience and deliver the best possible result for the brand. 
`,
      transportation: `Comprising 30”and 15” TVCs as well as YouTube bumpers, the ‘Behind the Curtain’ campaign was amplified via a FTA and VOD media strategy and supported with digital initiatives including an always on social media program. 
`,
    },
  },

  {
    imgFile: "clag",
    title: "Clag",
    subtitle: "120 YEARS OF CLAG",
    video:
      "https://res.cloudinary.com/communicado/video/upload/v1579577606/Website/CASE_STUDY_8_Bostik.mp4",
    body: {
      mapping: `Iconic brand Clag was approaching its 120 year celebration and the CMC team was challenged to build awareness of the brand’s versatility and longevity across social platforms. Needless to say, we saw the opportunity to get creative!`,
      starting: `Our strategy was to showcase the many possibilities for easy art and craft projects and also leverage the nostalgia associated with the brand by focussing on parent’s own memories of using the product as children. The campaign achieved thousands of click throughs to key retailers, over half a million impressions and 650+ competition entries. 
`,
      going: `Rather than cutting corners and purchasing pre-made art, the CMC social team embraced the occasion and crafted each piece featured in the content series by hand. This even included the painstaking paper mache process!
`,
      transportation: `We produced an engaging social video content series which was further amplified with an insight led social competition as well as a paid social media strategy. 
`,
    },
  },
  {
    imgFile: "carpet-court",
    title: "Carpet Court",
    subtitle: "Dream It. Style It. Live It.",
    body: {
      mapping: `The CMC team was appointed as a year on year PR & Social Media partner for the Carpet Court brand. Being key inspiration channels within the home and lifestyle category, we saw the opportunity to connect with consumers at the very start of their renovation journey.
`,
      starting: `Our strategy was not only to amplify Carpet Court’s new branding and ATL campaigns through a year long PR & Social Always On program, but also to drive the program with a fully integrated approach to ensure synergy between PR and social tactics. To date, the CMC team has significantly exceeded the results of previous years’ with more than 400 media clippings achieving a reach in excess of 50 million as well as social impressions in the hundreds of thousands.
`,
      going: `At CMC, media relations are our bread and butter. The CMC PR team has nurtured decade long relationships with key media contacts in the home and lifestyle space which arms us with the insights into the content that will guarantee a story. Meanwhile, the CMC social team are dedicated to watching each episode of The Block to jump on Carpet Court content opportunities for social channels the next day!
`,
      transportation: `The CMC team employ a variety of tactics to maintain Carpet Court’s share of voice including PR campaigns, social media content across Facebook, Instagram and Pinterest as well as nurturing relationships with ambassadors and influencers. 
`,
    },
  },
]
