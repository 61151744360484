import React, { useRef } from "react"
import {
  TilePopUpContainer,
  VideoThumbnail,
  InfoWrapper,
  PopUpTitle,
  PopUpSubtitle,
  SmallTitle,
  CloseButton,
  PlayButton,
  ReactVideo,
  CaseImg,
} from "./style"

import playIcon from "images/play-btn.png"
import { Icon } from "utils/getIcon"
import useOutsideAlerter from "hooks/useOutsiderAlerter"

const TilePopUp = ({
  showPopUp,
  title,
  subtitle,
  body,
  video,
  caseImg,
  videoClicked,
  setVideoClicked,
  closeHandler,
}) => {
  const popUpRef = useRef(null)

  useOutsideAlerter(popUpRef, () => {
    if (showPopUp) {
      closeHandler()
    }
  })

  if (caseImg) {
    caseImg.node.childImageSharp.fluid.sizes = "(min-width: 768px) 534px, 100vw"
  }

  return (
    <TilePopUpContainer ref={popUpRef} showPopUp={showPopUp}>
      <VideoThumbnail
        videoClicked={videoClicked}
        somersby={title === "Somersby"}
        woodstock={title === "Woodstock"}
        vcs={title === "VCS Foundation"}
      >
        {video && (
          <PlayButton videoClicked={videoClicked}>
            <img src={playIcon} alt="" />
            <h5>WATCH</h5>
          </PlayButton>
        )}

        {caseImg && <CaseImg fluid={caseImg.node.childImageSharp.fluid} />}

        {video && (
          <ReactVideo
            // controls={true}
            url={showPopUp ? video : null}
            // playing={videoClicked ? true : false}
            onClick={() => setVideoClicked(true)}
            controls={videoClicked ? true : false}
          />
        )}
      </VideoThumbnail>
      <InfoWrapper>
        <PopUpTitle>{title}</PopUpTitle>
        <PopUpSubtitle>{subtitle}</PopUpSubtitle>
        <SmallTitle>Mapping the destination:</SmallTitle>
        <p>{body.mapping}</p>
        <SmallTitle>Starting where others stop:</SmallTitle>
        <p>{body.starting}</p>

        <SmallTitle>Going beyond the expected:</SmallTitle>
        <p>{body.going}</p>

        <SmallTitle>Transportation:</SmallTitle>
        <p>{body.transportation}</p>

        {body.end && <p>{body.end}</p>}

        <CloseButton
          onClick={e => {
            e.stopPropagation()
            closeHandler()
          }}
        >
          <Icon name="cross" />
        </CloseButton>
      </InfoWrapper>
    </TilePopUpContainer>
  )
}

export default TilePopUp
