import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { CaseStudyGrid } from "components/CaseStudyGrid"
import { PageHeaderSection } from "components/PageHeaderSection"
import LogoWall from "../components/LogoWall/LogoWall"

const WorkPage = () => {
  return (
    <Layout>
      <SEO
        title="Our big ideas"
        description="A little sample of what we can do."
      />
      <PageHeaderSection
        title="Our big ideas"
        text1={`A little sample of what we can do.`}
      />
      <CaseStudyGrid />
      <LogoWall />
    </Layout>
  )
}

export default WorkPage
