import { useStaticQuery, graphql } from "gatsby"

export const GetCaseStudyCaseImages = () => {
  const data = useStaticQuery(
    graphql`
      query caseImages {
        allFile(
          filter: { absolutePath: { regex: "/case-study-case-images/" } }
          sort: { fields: [name], order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(srcSetBreakpoints: [534, 768], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  return data.allFile.edges
}
